import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { InvoicePayment } from '../../models/invoice-payment.model';
import { InvoiceService } from '../../services/invoice.service';
import { formatDate } from '@angular/common';
import { Invoice } from '../../models/invoice.model';
import { SFAccountSettingsService } from "../../services/sf-account-settings.service";
import { Utilities } from '../../services/utilities';
import { AlertService, MessageSeverity } from '../../services/alert.service';

@Component({
  selector: 'invoice-payment-history',
  templateUrl: './invoice-payment-history.component.html',
  styleUrls: ['./invoice-payment-history.component.css'],
  animations: [fadeInOut]
})

export class InvoicePaymentHistoryComponent implements OnInit, AfterContentChecked {
  loaderHidden: boolean;
  invoices: Invoice[];
  payments: InvoicePayment[];

  constructor(
    public configurations: ConfigurationService,
    private invoiceService: InvoiceService,
    private sfAccountSettingsService: SFAccountSettingsService,
    private alertService: AlertService,
    private cdref: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }

  ngOnInit() {
    this.loaderHidden = false;
    this.getInvoices();
  }

  //gets all the invoices from the Invoice Endpoint
  getInvoices() {
    this.invoiceService.getPaidInvoices().subscribe(results => { this.onInvoicesSuccessfullyLoaded(results) },
      error => this.onInvoicesFailed(error));
  }

  //successful subscribe
  private onInvoicesSuccessfullyLoaded(paidInvoices: Invoice[]) {
    this.invoices = paidInvoices;
    this.payments = [];
    this.populatePayments();
    this.hideLoader();
  }

  //unsuccessful subscribe
  private onInvoicesFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage("Load Error", `Unable to retrieve account settings from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`,
      MessageSeverity.error, error);
    this.hideLoader();
  }

  populatePayments() {
    for (var i = 0; i < this.invoices.length; i++) {
      for (var j = 0; j < this.invoices[i].payments.length; j++) {
        if (!this.payments.some(x => x.confirmationNumber == this.invoices[i].payments[j].confirmationNumber)) this.payments.push(this.invoices[i].payments[j]);
        var payment = this.payments.find(x => x.confirmationNumber == this.invoices[i].payments[j].confirmationNumber);
        if (payment.invoices == null) payment.invoices = [];
        if (!payment.invoices.some(x => x.invoiceNumber == this.invoices[i].invoiceNumber)) payment.invoices.push(this.invoices[i]);
      }
    }

    //sorting for payment list done here
    this.payments.sort(function (a, b): any {
      return (Date.parse(b.dateFinalized.toString()) - Date.parse(a.dateFinalized.toString()));
    });

  }

  getFormattedDate(payment: InvoicePayment) {
    if (payment.dateFinalized != null) {
      return formatDate(payment.dateFinalized, "dd-MMM-yyyy", "en-us");
    }
  }

  getTransactionAmount(payment: InvoicePayment) {
    return payment.transactionAmount.toFixed(2);
  }

  //get methods for invoice properties for angular
  getInvoicePdf(invoiceNumber: string) {
    this.invoiceService.getInvoicePDF(invoiceNumber).subscribe(
      response => {
        setTimeout(() => {
          window.open('/api/invoice/download?id=' + response.id, 'target="_blank"');
        }, 500);
      });
  }

  hideLoader() {
    this.loaderHidden = true;
  }
}
